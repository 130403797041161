import React from "react"
import { Link } from "gatsby"
import "./index.scss"

const Button = ({ children, styles, onClick, to, type }) => {
  return type ? (
    <button
      type={type ? type : "button"}
      onClick={onClick ? onClick : null}
      className={`btn ${styles ? styles : ""}`}
    >
      {children}
    </button>
  ) : (
    <Link style={{ textDecoration: "none" }} to={to || "#"}>
      <button
        onClick={onClick ? onClick : null}
        className={`btn ${styles ? styles : ""}`}
      >
        {children}
      </button>
    </Link>
  )
}

export default Button
